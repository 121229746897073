import axios from 'axios';
import pako from 'pako';

// Axios config
const config = {
  baseURL: '',
  timeout: 60000,

  // Only throw if HTTP error > 4XX
  validateStatus: function (status) {
    return status < 500;
  }
}

// Create the axios singleton
var instance = axios.create(config);

/**
 * HTTP Client
 *
 * HTTP client wrapper for normal HTTP requests
 */
export class Client {
  /**
   * Set base URL as the default for making requests
   *
   * @param {string} url
   */
  static async setBaseURL(url) {
    instance.defaults.baseURL = url;
  }

  static async setToken(tokenType, token) {
    instance.defaults.headers.common["Authorization"] = `${tokenType} ${token}`;
  }

  static async getToken() {
    const token = instance.defaults.headers.common["Authorization"].split('Bearer ')[1];

    return token;
  }

  /**
   * Send a normal HTTP GET request
   *
   * @param {string} url Resource endpoint
   * @param {object} qs Query string
   * @param {object} config Axios config
   */
  static async get(url, qs, config) {
    try {
      const res = await axios.get(url, config);

      if (res.status !== 200)
        throw (res);

      return res.data;

    } catch (e) {
      throw (e)
    }
  }

  static async getGz(url) {
    try {
      const res = await axios.get(url, { responseType: 'arraybuffer' });

      if (res.status !== 200)
        throw (res);

      return JSON.parse(pako.ungzip(res.data,{ to: 'string' }));
    } catch (e) {
      throw (e)
    }
  }

  /**
   * Get data from backend
   *
   * @param {object} data Query string
   */
  static async getData(url) {
    try {
      if (!instance.defaults.baseURL)
        throw Error("BaseURL not set!")

      const res = await instance.get(url);

      if (res.status !== 200)
        throw (res);

      return res.data;
    } catch (e) {
      throw (e)
    }
  }

  /**
   * Post data to backend
   *
   * @param {object} data Query string
   */
  static async postData(url, data) {
    try {
      if (!instance.defaults.baseURL)
        throw Error("BaseURL not set!")

      const res = await instance.post(url, { ...data });

      if (res.status !== 200)
        throw (res);

      return res.data;
    } catch (e) {
      throw (e)
    }
  }

  /**
   * Send a normal HTTP POST request
   *
   * @param {string} url Resource endpoint
   * @param {object} qs Query string
   * @param {object} config Axios config
   */
  static async post(url, data, config) {
    try {
      const res = await axios.post(url, data, config)

      if (res.status !== 200)
        throw (res);

      return res.data;
    } catch (e) {
      throw (e)
    }
  }
}