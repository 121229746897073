export const SET_APP_MODE = 'app/set_mode';
export const setAppMode = (data) => ({
  type: SET_APP_MODE,
  payload: data
});

export const ON_LOAD = 'app/on_load';
export const onLoad = (data) => ({
  type: ON_LOAD,
  payload: data
});

export const ON_LOAD_SUCCESS = 'app/on_load_success';
export const onLoadSuccess = (data, nationalityList, parameter, captchaKey) => ({
  type: ON_LOAD_SUCCESS,
  payload: {
    decryptData: data,
    nationalityList: nationalityList,
    parameter: parameter,
    captchaKey: captchaKey
  }
});

export const TOGGLE_LOADING = 'app/toggle_loading';
export const toggleLoading = () => ({
  type: TOGGLE_LOADING
});

export const SET_ERROR = 'app/error';
export const setError = (title, message) => ({
  type: SET_ERROR,
  payload: {
    title: title,
    message: message
  }
});

export const TOGGLE_TOAST = 'app/toggle_toast';
export const toggleToast = (data) => ({
  type: TOGGLE_TOAST,
  payload: data
});

export const ENCRYPT_LOAD = 'app/encrypt_load';
export const encryptLoad = (data) => ({
  type: ENCRYPT_LOAD,
  payload: data
});

export const ENCRYPT_LOAD_SUCCESS = 'app/encrypt_load_success';
export const encryptLoadSuccess = (data) => ({
  type: ENCRYPT_LOAD_SUCCESS,
  payload: data
});