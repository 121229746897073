export const SEND_OTP = 'otp/send_otp';
export const sendOTP = (data) => ({
  type: SEND_OTP,
  payload: data
});

export const SEND_OTP_SUCCESS = 'otp/send_otp_success';
export const sendOTPSuccess = (data) => ({
  type: SEND_OTP_SUCCESS,
  payload: data
});

export const VERIFY_OTP = 'otp/verify_otp';
export const verifyOTP = (data, form, paraObj, contactId) => ({
  type: VERIFY_OTP,
  payload: {
    otp: data,
    form: form,
    parameter: paraObj,
    contactId: contactId
  }
});

export const VERIFY_OTP_SUCCESS = 'otp/verify_otp_success';
export const verifyOTPSuccess = (data) => ({
  type: VERIFY_OTP_SUCCESS,
  payload: data
});

export const VERIFY_OTP_FAIL = 'otp/verify_otp_fail';
export const verifyOTPFail = () => ({
  type: VERIFY_OTP_FAIL
});

export const VERIFY_OTP_DUPLICATE = 'otp/verify_otp_duplicate';
export const verifyOTPDuplicate = (data) => ({
  type: VERIFY_OTP_DUPLICATE,
  payload: data
});

export const VERIFY_OTP_EXPIRED = 'otp/verify_otp_expired';
export const verifyOTPExpired = () => ({
  type: VERIFY_OTP_EXPIRED
});
