import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import moment from 'moment';

import { onLoad, toggleToast, toggleLoading, encryptLoad } from './actions';

import styles from './index.module.css';

const Header = loadable(() => import('../components/Header'));
const Footer = loadable(() => import('../components/Footer'));
const FormPage = loadable(() => import('../page/Form'));
const OTPPage = loadable(() => import('../page/OTP'));
const SubmitPage = loadable(() => import('../page/Submit'));
const ErrorPage = loadable(() => import('../page/Error'));
const LoadingPage = loadable(() => import('../page/Loading'));
const NotificationPopup = loadable(() => import('../components/Notification'));

function App(props) {
  const { appMode, onLoad, loading, error, toast, toggleToast, toastMessage, toggleLoading, encryptLoad, encryptPayload } = props;
  const [height, setHeight] = useState(68);
  const [encryptMode, setEncryptMode] = useState(false);
  const [payload, setPayload] = useState(null);

  useEffect(() => {
    window.addEventListener("resize", resetHeight);

    return () => {
      window.removeEventListener("resize", resetHeight);
    };
  }, []);

  useEffect(() => {
    if (window.location.pathname === '/encrypt') {
      let search = decodeURI(window.location.search);
      search = search.split('?')[1];
      const parObj = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
      const newParObj = Object.assign({}, parObj);
      newParObj.residentialAddress = {
        block: newParObj.block,
        streetName: newParObj.street,
        unitNo: newParObj.unitNum,
        buildingName: newParObj.building,
        postalcode: newParObj.postalCode
      };
      delete newParObj.channel;
      delete newParObj.dealercode;
      delete newParObj.salesid;
      delete newParObj.block;
      delete newParObj.streetName;
      delete newParObj.unitNo;
      delete newParObj.building;
      delete newParObj.postalcode;
      newParObj.mobileNumber = newParObj.mobileNumber.split(',');
      newParObj.timestamp = moment().utc().format('YYYYMMDDHHmmss');

      if (!parObj.channel) {
        parObj.channel = 'SMA';
      }

      if (!parObj.dealercode) {
        parObj.dealercode = 'A123';
      }

      if (!parObj.salesid) {
        parObj.salesid = 'Steve';
      }

      setPayload(newParObj);
      encryptLoad(newParObj);
      setEncryptMode(true);
    } else {
      onLoad(window.location);
    }
  }, [onLoad]);

  useEffect(() => {
    if (appMode !== 'initial') {
      resetHeight();
    }
  }, [appMode]);

  const resetHeight = () => {
    if (document.getElementsByClassName('header')[0]) {
      if (document.getElementsByClassName('header')[0].clientHeight) {
        setHeight(document.getElementsByClassName('header')[0].clientHeight);
      }
    }
  }

  const goLink = (link, newTab) => {
    if (newTab) {
      window.open(link, '_blank', 'noopener');
    } else {
      window.location.assign(link);
    }
  }

  const goHome = () => {
    goLink('https://www.singtel.com/personal/products-services/lifestyle-services/insurance/life-protect/bill-protect');
  }

  const displayPage = () => {
    if (encryptMode) {
      let search = decodeURI(window.location.search);
      search = search.split('?')[1];
      const parObj = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');

      if (!parObj.channel) {
        parObj.channel = 'SMA';
        parObj.dealercode = 'A123';
        parObj.salesid = 'Steve';
      } else if (parObj.channel === 'SMA' && !(parObj.salesid && parObj.dealercode)) {
        parObj.dealercode = 'A123';
        parObj.salesid = 'Steve';
      }

      const encryptData = encryptPayload;

      const newLink = `https://lifeprotect.singtel.tv/insurance/subscribe/hook?data=${encryptData}&channel=${parObj.channel}&klbl=test${parObj.salesid ? `&salesid=${parObj.salesid}` : ""}${parObj.dealercode ? `&dealercode=${parObj.dealercode}` : ""}`;

      console.log(JSON.stringify(payload).split('{'));
      const strPar = JSON.stringify(payload).split('{')[1].split('}')[0].split(',');
      const strParList = strPar.map((e, index) => {
        return (
          <div key={`enc-${index}`}>
            {e}
          </div>
        )
      })

      return (
        <div className={styles['encrypt-wrapper']}>
          <div>
            {strParList}
            {JSON.stringify(payload.residentialAddress)}
          </div>
          <a href={newLink}>Go SIT</a>
        </div>
      );
    } else {
      switch (appMode) {
        case 'form':
          window.history.replaceState({}, "", '/');

          return (
            <FormPage
              resetHeight={resetHeight}
              goLink={goLink}
            />
          );

        case 'otp':
          return (
            <OTPPage />
          );

        case 'submit':
          window.history.replaceState({}, "", '/');

          return (
            <SubmitPage
              goLink={goLink}
              goHome={goHome}
            />
          );

        default:
          return true;
      }
    }
  }

  return (
    <div className={styles['wrapper']}>
      <div className="header">
        <Header />
      </div>

      <div className={`${styles['container']} page-wrapper ${loading || error || appMode === 'otp' ? styles['loading'] : ''}`} style={{ height: `calc(100% - ${height}px`}}>
        {
          error ?
            <ErrorPage
              onClick={() => goHome()}
              resetHeight={resetHeight}
            />
            :
            displayPage()
        }

        {
          !error && loading ?
            <LoadingPage />
            : ""
        }

        <div className={styles['footer']}>
          <Footer />
        </div>
      </div>

      <NotificationPopup
        visible={toast}
        onClose={toggleToast}
        message={toastMessage}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  appMode: state.app.appMode,
  loading: state.app.loading,
  error: state.app.error,
  toast: state.app.toast,
  toastMessage: state.app.toastMessage,
  encryptPayload: state.app.encryptPayload
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onLoad,
      toggleToast,
      toggleLoading,
      encryptLoad
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
