import { takeEvery, put, call, fork } from 'redux-saga/effects';

import { Client } from '../../utils/client';
import { SEARCH_POSTAL, searchPostalSuccess, VERIFY_CAPTCHA, verifyCaptchaSuccess } from './actions';
import { setError } from '../../redux-root/actions';

const recaptchaErrorList = ['7012', '7013', '7014', '7015', '7016'];

export function* searchPostal(action) {
  const { payload } = action;

  const data = yield call(Client.get, `https://developers.onemap.sg/commonapi/search?searchVal=${payload}&returnGeom=N&getAddrDetails=Y&pageNum=1`);

  yield put(searchPostalSuccess(data.results));
}

export function* verifyCaptcha(action) {
  const { token } = action;

  try {
    const request = {
      response: token
    };

    const data = yield call(Client.postData, '/captcha', request);

    if (recaptchaErrorList.includes(data.errcode)) {
      yield put(setError(`Sorry, this isn't working at the moment`, 'This may be due to a technical difficulty. Please try again later.'));
    } else {
      yield put(verifyCaptchaSuccess(data.results));
    }
  } catch (error) {
    console.log(error);

    yield put(setError(`Sorry, this isn't working at the moment`, 'This may be due to a technical difficulty. Please try again later.'));
  }
}

function* watchOnSearch() {
  yield takeEvery(SEARCH_POSTAL, searchPostal);
}

function* watchVerifyCaptcha() {
  yield takeEvery(VERIFY_CAPTCHA, verifyCaptcha);
}

export function* formSaga() {
  yield fork(watchOnSearch);
  yield fork(watchVerifyCaptcha);
}
