import { takeEvery, put, call, fork } from 'redux-saga/effects';

import { Client } from '../../utils/client';
import { SEND_OTP, sendOTPSuccess, VERIFY_OTP, verifyOTPSuccess, verifyOTPFail, verifyOTPExpired } from './actions';
import { setError } from '../../redux-root/actions';

export function* sendOTP(action) {
  const { payload } = action;

  const requestData = {
    email: payload
  };

  try {
    const response = yield call(Client.postData, '/sendotp', requestData);

    // const response = {
    //   "errcode":"0",
    //   "errmsg":"success",
    //   "data": {
    //     "otpId":5
    //   }
    // };

    if (response.errcode === '1004') {
      yield put(setError('Sorry, your session has been timed out', 'For your security, we’ve ended your session. Please try again.'));
    } else if (response.errcode === '1003' || response.error === '807') {
      yield put(setError(`Sorry, this isn't working at the moment`, 'This may be due to a technical difficulty. Please try again later.'));
    } else if (response.errmsg === 'success') {
      yield put(sendOTPSuccess(response.data));
    } else {
      yield put(setError(`Sorry, this isn't working at the moment`, 'This may be due to a technical difficulty. Please try again later.'));
    }
  } catch (error) {
    yield put(setError(`Sorry, this isn't working at the moment`, 'This may be due to a technical difficulty. Please try again later.'));
  }
}

export function* verifyOTP(action) {
  const { payload } = action;

  let requestData = {
    ...payload.form.proposer,
    ...payload.form.proposer.address,
    channel: payload.parameter.channel,
    salesID: payload.parameter.salesid,
    dealerCode: payload.parameter.dealercode,
  };
  delete requestData.address;

  if (payload.form.proposer.address.unit) {
    let formatUnit = payload.form.proposer.address.unit.split('-');
  
    if (formatUnit[0].length === 1) {
      formatUnit[0] = `0${formatUnit[0]}`;
    }

    if (formatUnit[1].length === 1) {
      formatUnit[1] = `0${formatUnit[1]}`;
    }

    if (formatUnit.length === 2) {
      requestData.unit = formatUnit[0] + '-' + formatUnit[1];
    }
  }

  if (payload.contactId) {
    requestData.contactId = payload.contactId;
  }

  try {
    const response = yield call(Client.postData, '/savecustomerinfo', requestData);

    // const response = {
    //   errcode: '0',
    //   errmsg: 'success',
    //   data: {
    //     content: {
    //       policyNo: 1234567890
    //     }
    //   }
    // };

    if (response.errcode === '1004') {
      yield put(setError('Sorry, your session has been timed out', 'For your security, we’ve ended your session. Please try again.'));
    } else if (response.errcode === '1003' || response.errcode === '804' || response.errcode === '805' || response.errcode === '806') {
      yield put(setError(`Sorry, this isn't working at the moment`, 'This may be due to a technical difficulty. Please try again later.'));
    } else if (response.errcode === '803') {
      yield put(verifyOTPFail());
    } else if (response.errcode === '808') {
      yield put(verifyOTPExpired());
    } else if (response.errcode === 'ERR_GEN_ACTIVEPOLICY') {
      yield put(setError(`Sorry, this isn't working at the moment`, 'This may be due to a technical difficulty. Please try again later.'));
    } else if (response.errcode === 'ERR_GEN_INVALIDINPUTREQUEST') {
      yield put(setError(`Unable to process application`, 'There was an issue reviewing the details you provided. Call Etiqa at <a href="#">6887 8777</a> for further assistance.'));
    } else if (response.errcode === 'ERR_GEN_DOB') {
      yield put(setError(`Unable to process application`, 'There was an issue reviewing your age eligibility. Call Etiqa at <a href="#">6887 8777</a> for further assistance.'));
    } else if (response.errmsg === 'success') {
      yield put(verifyOTPSuccess(response.data));
    } else {
      // DISPLAY SUBMIT FAIL
      yield put(setError(`Sorry, this isn't working at the moment`, 'This may be due to a technical difficulty. Please try again later.'));
    }
  } catch (error) {
    yield put(setError(`Sorry, this isn't working at the moment`, 'This may be due to a technical difficulty. Please try again later.'));
  }
}

function* watchSendOTP() {
  yield takeEvery(SEND_OTP, sendOTP);
}

function* watchVerifyOTP() {
  yield takeEvery(VERIFY_OTP, verifyOTP);
}

export function* otpSaga() {
  yield fork(watchSendOTP);
  yield fork(watchVerifyOTP);
}
