export const SEARCH_POSTAL = 'form/search_postal';
export const searchPostal = (data) => ({
  type: SEARCH_POSTAL,
  payload: data
});

export const SEARCH_POSTAL_SUCCESS = 'form/search_postal_success';
export const searchPostalSuccess = (data) => ({
  type: SEARCH_POSTAL_SUCCESS,
  payload: data
});

export const SAVE_DATA = 'form/save_data';
export const saveData = (data) => ({
  type: SAVE_DATA,
  payload: data
});

export const VERIFY_CAPTCHA = 'form/verify_captcha';
export const verifyCaptcha = (token) => ({
  type: VERIFY_CAPTCHA,
  token: token
});

export const VERIFY_CAPTCHA_SUCCESS = 'form/verify_captcha_success';
export const verifyCaptchaSuccess = (data) => ({
  type: VERIFY_CAPTCHA_SUCCESS,
  payload: data
});