import {
  SEND_OTP,
  SEND_OTP_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAIL,
  VERIFY_OTP_DUPLICATE,
  VERIFY_OTP_EXPIRED
} from './actions';

const initialState = {
  otpData: null,
  verifyData: null,
  verifyError: false,
  verifyExpired: false,
  sending: false,
  duplicateError: false
};

export function otpReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case SEND_OTP:
      return {
        ...state,
        sending: true
      };

    case SEND_OTP_SUCCESS:
      return {
        ...state,
        otpData: data,
        verifyError: false,
        verifyExpired: false,
        sending: false
      };

    case VERIFY_OTP:
      return {
        ...state,
        verifyError: false
      }

    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        verifyData: data
      };

    case VERIFY_OTP_FAIL:
      return {
        ...state,
        verifyError: true
      }

    case VERIFY_OTP_DUPLICATE:
      return {
        ...state,
        duplicateError: true,
        verifyData: data
      }

    case VERIFY_OTP_EXPIRED:
      return {
        ...state,
        verifyExpired: true
      }

    default:
      return state;
  }
}
