import { combineReducers } from 'redux';
import { appReducer } from '../app/reducer';
import { formReducer } from '../page/Form/reducer';
import { otpReducer } from '../page/OTP/reducer';

export default combineReducers({
  app: appReducer,
  form: formReducer,
  otp: otpReducer
})
