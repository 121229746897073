import {
  SEARCH_POSTAL_SUCCESS,
  SAVE_DATA,
  VERIFY_CAPTCHA_SUCCESS
} from './actions';

const initialState = {
  addressData: null,
  formData: null,
  captchaSuccess: false
};

export function formReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case SEARCH_POSTAL_SUCCESS:
      return {
        ...state,
        addressData: data
      };

    case SAVE_DATA:
      return {
        ...state,
        formData: data
      }

    case VERIFY_CAPTCHA_SUCCESS:
      return {
        ...state,
        captchaSuccess: true
      }

    default:
      return state;
  }
}
