import {
  SET_APP_MODE,
  ON_LOAD_SUCCESS,
  TOGGLE_LOADING,
  SET_ERROR,
  TOGGLE_TOAST,
  ENCRYPT_LOAD_SUCCESS
} from './actions';
import {
  SAVE_DATA,
  SEND_OTP_SUCCESS,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_DUPLICATE
} from '../redux-root/actions';

const flowList = ['initial', 'form', 'otp', 'submit'];

const initialState = {
  appMode: flowList[0],
  decryptData: null,
  nationalityList: [],
  loading: true,
  error: null,
  toast: false,
  toastMessage: null,
  paraObj: null,
  encryptPayload: null,
  captchaKey: null
};

export function appReducer(state = initialState, action) {
  let data = action.payload;

  switch (action.type) {
    case SET_APP_MODE:
      return {
        ...state,
        appMode: data
      };

    case ON_LOAD_SUCCESS:
      return {
        ...state,
        decryptData: data.decryptData,
        nationalityList: data.nationalityList,
        paraObj: data.parameter,
        captchaKey: data.captchaKey,
        appMode: flowList[1],
        loading: false
      };

    case SAVE_DATA:
      return {
        ...state,
        appMode: flowList[2]
      };

    case SEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading
      };

    case VERIFY_OTP_SUCCESS:
    case VERIFY_OTP_DUPLICATE:
      return {
        ...state,
        loading: false,
        appMode: flowList[3]
      };

    case SET_ERROR:
      return {
        ...state,
        error: {
          title: data.title,
          message: data.message
        }
      };

    case TOGGLE_TOAST:
      let newData = data;

      if (!data) {
        newData = null;
      }

      return {
        ...state,
        toast: !state.toast,
        toastMessage: newData
      }

    case ENCRYPT_LOAD_SUCCESS:
      return {
        ...state,
        encryptPayload: data,
        loading: false
      }

    default:
      return state;
  }
}
