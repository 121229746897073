import { fork } from 'redux-saga/effects';
import { appSaga } from '../app/saga';
import { formSaga } from '../page/Form/saga';
import { otpSaga } from '../page/OTP/saga';

export function* rootSaga() {
  yield fork(appSaga);
  yield fork(formSaga);
  yield fork(otpSaga);
}
