import { takeEvery, put, call, fork } from 'redux-saga/effects';

import { Client } from '../utils/client';
import { ON_LOAD, onLoadSuccess, setError, ENCRYPT_LOAD, encryptLoadSuccess } from './actions';
import { verifyOTPDuplicate } from '../redux-root/actions';
import { nricValidator } from '../utils/nricValidator';

const residencyList = [
  {
    channelValue: 'NRIC',
    selectValue: 1
  },
  {
    channelValue: 'FEP',
    selectValue: 2
  },
  {
    channelValue: 'FSP',
    selectValue: 3
  },
  {
    channelValue: 'FWP',
    selectValue: 4
  },
  {
    channelValue: 'FDP',
    selectValue: 5
  },
  {
    channelValue: 'FSTP',
    selectValue: 6
  },
  {
    channelValue: 'DIPLOMAT',
    selectValue: 7
  }
];

const channelList = ['eshop', 'sma', 'ecare', 'msta', 'crm'];

const alphaNumericRegex = new RegExp("^[a-zA-Z0-9]+$");
const salesRegex = new RegExp("^[a-zA-Z0-9.]+$");
const nameRegex = new RegExp("[^a-zA-Z0-9.,+-/()'&@ ]", "g");
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;

export function* initSession(action) {
  const { payload } = action;

  Client.setBaseURL(`${process.env.REACT_APP_API_URL}/insurance/subscribe`);

  if (payload && payload.pathname === "/insurance/subscribe/hook") {
    const search = payload.search.substring(1);

    try {
      const parObj = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');

      if (
        !parObj.data ||
        !channelList.includes(parObj.channel.toLowerCase()) ||
        (parObj.consumerType && parObj.consumerType.toLowerCase() !== 'consumer') ||
        (parObj.channel === 'SMA' && ((!parObj.salesid || (parObj.salesid && !salesRegex.test(parObj.salesid))) || (!parObj.dealercode || (parObj.dealercode && !alphaNumericRegex.test(parObj.dealercode)))))
      ) {
        yield put(setError(`Sorry, this isn't working at the moment`, 'This may be due to a technical difficulty. Please try again later.'));
      } else {
        let validationError = false;
        let blacklistError = false;

        const decryptData = yield call(Client.getData, `/hook?${search}`);

        if (decryptData.data && decryptData.data.policyNumber) {
          const verifyData = {
            content: {
              policyNo: decryptData.data.policyNumber
            }
          };

          yield put(verifyOTPDuplicate(verifyData));
        } else if (decryptData.errcode === '1004' || decryptData.errcode === '8001') {
          yield put(setError('Sorry, your session has been timed out', 'For your security, we’ve ended your session. Please try again.'));
        } else if (decryptData.errcode === '9001') {
          yield put(setError(`Sign-up has closed`, 'All slots have been snapped up. Keep a lookout for upcoming promotions.'));
        } else {
          if (decryptData.data.token) {
            Client.setToken('Bearer', decryptData.data.token);
          }

          let validationData = decryptData.data.body;

          if (validationData) {
            if (validationData.name) {
              validationData.name = validationData.name.replace(nameRegex, '');
            } else {
              validationError = true;
            }

            if (!validationData.id || (validationData.id && !nricValidator(validationData.id))) {
              validationError = true;
            }

            if (!validationData.eligibility || (parObj.channel !== 'SMA' && validationData.eligibility && validationData.eligibility !== 'NRIC') || !residencyList.filter(obj => validationData.eligibility === obj.channelValue)[0]) {
              validationError = true;

              if (validationData.eligibility && ((parObj.channel !== 'SMA' && validationData.eligibility !== 'NRIC') || !residencyList.filter(obj => validationData.eligibility === obj.channelValue)[0])) {
                blacklistError = true;
              }
            }

            if (!validationData.mobileNumber || validationData.mobileNumber.length === 0) {
              validationError = true;
            } else {
              const filteredNumber = validationData.mobileNumber.filter(no => (no.length !== 8 || isNaN(no)));
              
              if (filteredNumber[0]) {
                validationError = true;
              }
            }

            if (validationData.emailAddress && !emailRegex.test(validationData.emailAddress)) {
              validationError = true;
            }
          }

          if (decryptData.errcode === '6002') {
            blacklistError = true;
          }

          if (!validationError && !blacklistError) {
            const nationalityData = yield call(Client.getData, '/getnationality');

            if (nationalityData.errcode === '1004') {
              yield put(setError('Sorry, your session has been timed out', 'For your security, we’ve ended your session. Please try again.'));
            } else if (decryptData.errcode === '1001' || nationalityData.errcode === '1001' || nationalityData.errcode === '1003') {
              yield put(setError(`Sorry, this isn't working at the moment`, 'This may be due to a technical difficulty. Please try again later.'));
            } else {
              if (decryptData.data.body.eligibility) {
                decryptData.data.body.eligibility = residencyList.filter(obj => decryptData.data.body.eligibility === obj.channelValue)[0].selectValue;
              }

              yield put(onLoadSuccess(decryptData.data.body, nationalityData.data, parObj, decryptData.data.recaptchaSiteKey));
            }
          } else {
            if (blacklistError) {
              yield put(setError(`Sorry, your application for Singtel Bill Protect was unsuccessful.`, 'You may call Etiqa at <a href="#">6887 8777</a> to enquire about your eligibility for Singtel Bill Protect.'));
            } else {
              yield put(setError(`We're missing some personal information`, '<a href="http://help.singtel.com/chat" target="_blank">Reach out on WhatsApp</a> for further assistance.'));
            }
          }
        }
      }
    } catch (error) {
      yield put(setError(`Sorry, this isn't working at the moment`, 'This may be due to a technical difficulty. Please try again later.'));
    }
  } else {
    yield put(setError(`Sorry, this isn't working at the moment`, 'This may be due to a technical difficulty. Please try again later.'));
  }
}

export function* encryptLoad(action) {
  const { payload } = action;

  const strPayload = JSON.stringify(payload);

  const encData = yield call(Client.post, `${process.env.REACT_APP_API_URL}/insurance/test/encrypt`, strPayload, {
    headers: {
      'Content-Type': 'text/plain'
    }
  });
  const finalEncData = encodeURIComponent(encData.data);

  yield put(encryptLoadSuccess(finalEncData));
}

function* watchOnLoad() {
  yield takeEvery(ON_LOAD, initSession);
}

function* watchEncryptLoad() {
  yield takeEvery(ENCRYPT_LOAD, encryptLoad);
}

export function* appSaga() {
  yield fork(watchOnLoad);
  yield fork(watchEncryptLoad);
}
